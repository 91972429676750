import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Stack, Alert } from "react-bootstrap";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import isEmail from "validator/es/lib/isEmail.js";
import CreatableSelect from "react-select/creatable";
import ShortUniqueId from "short-unique-id";

const ModalContact = ({ show, handleClose, addContact, contact }) => {
  const { randomUUID } = new ShortUniqueId({ length: 10 });

  const initialState = {
    id: contact?.id || randomUUID(),
    prenom: contact?.prenom || "",
    nom: contact?.nom || "",
    fonction: contact?.fonction || "",
    email: contact?.email || "",
    telephone: contact?.telephone || "",
  };

  const [contactData, setContactData] = useState(initialState);
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    console.log("contact in modal", contact);
    if (contact) {
      console.log("contact !");
      setContactData(contact);
    } else {
      console.log("Pas contact !");
      setContactData(initialState);
    }
  }, [contact]);

  /* useEffect(() => {
    console.log("Updated contactData in useEffect", contactData);
  }, [contactData]); */

  const handleChange = ({ target: { name, value } }) => {
    setContactData((prevContactData) => ({
      ...prevContactData,
      [name]: value,
    }));

    if (name === "email") {
      setIsValid(isEmail(value));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isValid) {
      addContact(contactData);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{contact ? "Editer le contact" : "Ajouter un contact"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Prénom</Form.Label>
            <Form.Control type="text" name="prenom" value={contactData.prenom} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" name="nom" value={contactData.nom} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Fonction</Form.Label>
            <CreatableSelect
              isClearable
              options={[
                { value: "Gérant", label: "Gérant" },
                { value: "Chef de projet", label: "Chef de projet" },
              ]}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={"Selectionner..."}
              formatCreateLabel={(inputValue) => `Ajouter : "${inputValue}"`}
              onChange={(selectedOption) => setContactData({ ...contactData, fonction: selectedOption?.value || "" })}
              value={contactData.fonction ? { label: contactData.fonction, value: contactData.fonction } : null}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={contactData.email}
              isInvalid={!isValid}
              onChange={handleChange}
            />
            {!isValid && (
              <Alert className="mt-2 p-2" variant="danger">
                Email incorrect
              </Alert>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Téléphone</Form.Label>
            <PhoneInput
              defaultCountry="fr"
              hideDropdown={true}
              value={contactData.telephone}
              onChange={(phone) => setContactData({ ...contactData, telephone: phone })}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {contact ? "Enregistrer les modifications" : "Ajouter le contact"}
        </Button>
        <p>id : {contactData.id}</p>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalContact;
