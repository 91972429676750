import React, { useState, useEffect } from "react";
import { Form, Stack, Button, Row, Col, Container } from "react-bootstrap";
import TypeSelecteur from "../components/TypeSelecteur.js";
import DepartementSelecteur from "../components/DepartementSelecteur.js";
import RessourcesSelecteur from "../components/RessourcesSelecteur.js";
import OperationsSelecteur from "../components/OperationsSelecteur.js";
import ServicesSelecteur from "../components/ServicesSelecteur.js";
import CapaciteSelecteur from "../components/CapaciteSelecteur.js";
import ShortUniqueId from "short-unique-id";
import { postNewFiliere } from "../services/api.js";
import ModalContact from "../components/ModalContact.js";
import "react-international-phone/style.css";
import isURL from "validator/es/lib/isURL.js";

const formDataObject = {
  nom: "",
  fournisseur_reemploi: false,
  repreneur_reemploi: false,
  fournisseur_recyclage: false,
  repreneur_recyclage: false,
  type: [],
  insertion: false,
  ressources_entrantes: [],
  operations: [],
  services: [],
  adresse: "",
  departement: [],
  site_web: "",
  capacite: [null],
  interlocuteur: "",
  mail: [],
  contact: [],
  telephone: [],
  commentaires: "",
  statut: "Actif",
  update: "Mis à jour",
  ressources_sortantes: [],
};

const NouvelleFiliere = () => {
  const [showModal, setShowModal] = useState(false);
  const [editingContact, setEditingContact] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [formData, setFormData] = useState(formDataObject);

  const { randomUUID } = new ShortUniqueId({ length: 10 });

  useEffect(() => {
    if (editingContact) {
      console.log("editing contact in useEffect", editingContact);
    }
  }, [editingContact]);

  const handleShow = () => {
    setEditingContact(null);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setEditingContact(null);
  };

  const addContact = (contact) => {
    if (editingContact) {
      setFormData((prevData) => ({
        ...prevData,
        contact: prevData.contact.map((con) => (con.id === contact.id ? contact : con)),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        contact: [...prevData.contact, { ...contact, id: randomUUID() }],
      }));
    }
    handleClose();
  };

  const handleEditContact = (id) => {
    console.log("id depuis Form", id);
    const contactToEdit = formData.contact.find((con) => con.id === id);
    if (contactToEdit) {
      setEditingContact(contactToEdit);
      console.log("editingContact", contactToEdit);
      setShowModal(true);
    } else {
      console.log("No contact found with id", id);
    }
  };

  const handleDeleteContact = (id) => {
    setFormData((prevData) => ({
      ...prevData,
      contact: prevData.contact.filter((con) => con.id !== id),
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRessourcesChange = (selectedRessources) => {
    setFormData({ ...formData, ressources_entrantes: selectedRessources });
  };

  const handleDepartementChange = (selectedDepartement) => {
    setFormData({ ...formData, departement: selectedDepartement });
  };

  const handleServicesChange = (selectedServices) => {
    setFormData({ ...formData, services: selectedServices });
  };

  const handleTypeChange = (selectedType) => {
    setFormData({ ...formData, type: selectedType });
  };

  const handleOperationChange = (selectedOperation) => {
    setFormData({ ...formData, operations: selectedOperation });
  };

  const handleCapaciteChange = (valueSelected) => {
    setFormData({ ...formData, capacite: valueSelected.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postNewFiliere(formData);
      console.log(response);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const resetForm = () => {
    setFormData(formDataObject);
  };

  const handleChangeUrl = (event) => {
    const url = event.target.value;
    const { name } = event.target;
    setIsValid(isURL(url));
    setFormData({ ...formData, [name]: url });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Container className="d-flex justify-content-center p-3">
      <Col lg={8} sm={12} md={8}>
        <Row>
          <h1>Ajouter un acteur du réemploi</h1>
        </Row>
        <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <Form.Group className="my-2">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" name="nom" value={formData.nom} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="my-2">
            <TypeSelecteur selectedValues={formData.type} valueSelected={handleTypeChange} allowCreate={true} />
          </Form.Group>
          <Form.Group className="my-2">
            <Form.Check
              inline
              type="checkbox"
              name="fournisseur_reemploi"
              label="Fournisseur de matériaux de réemploi"
              checked={formData.fournisseur_reemploi}
              onChange={handleChange}
            />
            <Form.Check
              inline
              type="checkbox"
              name="repreneur_reemploi"
              label="Repreneur de matériaux de réemploi"
              checked={formData.repreneur_reemploi}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="my-2">
            <Form.Check
              inline
              type="checkbox"
              name="fournisseur_recyclage"
              label="Fournisseur de matériaux recyclés"
              checked={formData.fournisseur_recyclage}
              onChange={handleChange}
            />
            <Form.Check
              inline
              type="checkbox"
              name="repreneur_recyclage"
              label="Repreneur de matériaux pour recyclage"
              checked={formData.repreneur_recyclage}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="my-4">
            <Form.Check
              type="checkbox"
              name="insertion"
              label="Emploi de travailleurs en insertion"
              checked={formData.insertion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="my-2">
            <RessourcesSelecteur
              valueSelected={handleRessourcesChange}
              selectedValues={formData.ressources_entrantes}
              allowCreate={true}
            />
          </Form.Group>

          <Form.Group className="my-2">
            <OperationsSelecteur
              allowCreate={true}
              valueSelected={handleOperationChange}
              selectedValues={formData.operations}
            />
          </Form.Group>
          <Form.Group className="my-2">
            <ServicesSelecteur
              selectedValues={formData.services}
              valueSelected={handleServicesChange}
              allowCreate={true}
            />
          </Form.Group>
          <Form.Group className="my-2">
            <Row>
              <Col lg={7} sm={12}>
                <Form.Label>Adresse principale</Form.Label>
                <Form.Control type="text" name="adresse" value={formData.adresse} onChange={handleChange} />
              </Col>
              <Col lg={5} sm={12}>
                <DepartementSelecteur
                  selectedValues={formData.departement}
                  valueSelected={handleDepartementChange}
                  allowCreate={true}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="my-2">
            <Form.Label>Site Web</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!isValid}
              name="site_web"
              value={formData.site_web}
              onChange={handleChangeUrl}
            />
          </Form.Group>
          <Form.Group className="my-2">
            <CapaciteSelecteur selectedValues={formData.capacite} valueSelected={handleCapaciteChange} />
          </Form.Group>
          <Form.Group className="my-2">
            <Form.Label className="me-2">Contact</Form.Label>
            <Button variant="outline-secondary" onClick={handleShow}>
              Ajouter un contact
            </Button>
            {formData.contact && formData.contact.length > 0 ? (
              <Stack className="my-2" direction="horizontal" gap={2}>
                {formData.contact.map((con) => (
                  <div key={con.id} className="d-flex align-items-center">
                    <span>
                      <Button onClick={() => handleEditContact(con.id)}>
                        {con.prenom} {con.nom}
                      </Button>
                      <Button
                        className="mx-1"
                        variant="danger"
                        onClick={() => handleDeleteContact(con.id)}
                        title="Supprimer le contact"
                      >
                        ×
                      </Button>
                    </span>
                  </div>
                ))}
              </Stack>
            ) : null}
            <ModalContact show={showModal} handleClose={handleClose} addContact={addContact} contact={editingContact} />
          </Form.Group>
          <Form.Group className="my-2">
            <Form.Label>Commentaires</Form.Label>
            <Form.Control as="textarea" name="commentaires" value={formData.commentaires} onChange={handleChange} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Ajouter
          </Button>
        </Form>
      </Col>
    </Container>
  );
};

export default NouvelleFiliere;
