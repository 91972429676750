//Accueil.js
import React from "react";
import { useState } from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import Annuaire from "./Annuaire.js";
import { Link } from "react-router-dom";
import FichierUpload from "../services/FichierUpload.js";

function Accueil() {
  return (
    <Container>
      <Row>
        <h1>Retrace</h1>
        <Col sm={2}>
          <Stack gap={3}>
            <Button as={Link} to={"/annuaire"}>
              Consulter les filieres
            </Button>
            <Button as={Link} to={"/ajouterFiliere"}>
              Ajouter un acteur
            </Button>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default Accueil;
