//components/CapaciteSelecteur.js

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { fetchCapacite } from "../services/api.js";

function CapaciteSelecteur({ valueSelected, selectedValues }) {
  const [options, setOptions] = useState();
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (selectedValues != null) {
      setSelectedOption(selectedValues);
    } else {
      setSelectedOption(null);
    }
  }, [selectedValues]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    valueSelected(selectedOption);
  };

  const fetchOptions = async () => {
    try {
      const response = await fetchCapacite();
      const data = await response;

      const formattedOptions = data.map((item) => ({
        value: item,
        label: item,
      }));

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  return (
    <Form.Group>
      <Form.Label>Capacité</Form.Label>
      <Select
        value={selectedOption}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        placeholder={"Selectionner..."}
        noOptionsMessage={() => "Pas de résultat"}
      />
    </Form.Group>
  );
}
export default CapaciteSelecteur;
