//components/RessourcesSelecteur.js

import React, { useState, useEffect } from "react";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import { fetchRessourcesEnt } from "../services/api.js";
import Creatable from "react-select/creatable";
import { InfoCircle } from "react-bootstrap-icons";

function RessourcesSelecteur({ valueSelected, allowCreate, selectedValues }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (selectedValues) {
      const formattedSelectedValues = selectedValues.map((value) => ({
        value: value,
        label: value,
      }));
      setSelectedOption(formattedSelectedValues);
    }
  }, [selectedValues]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Matériaux et équipements repris par la filière
    </Tooltip>
  );

  const fetchOptions = async () => {
    try {
      const response = await fetchRessourcesEnt();
      const data = await response;

      const formattedOptions = data.map((item) => ({
        value: item,
        label: item,
      }));

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    valueSelected(selectedOption.map((item) => item.value));
  };

  const SelectComponent = allowCreate ? Creatable : Select;

  return (
    <Form.Group>
      <Form.Label>Matériaux entrants</Form.Label>
      <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        <InfoCircle className="m-2" variant="success" />
      </OverlayTrigger>
      <SelectComponent
        isMulti
        value={selectedOption}
        name="ressources"
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        placeholder={"Selectionner une ou plusieurs..."}
        formatCreateLabel={(inputValue) => `Ajouter : "${inputValue}"`}
        noOptionsMessage={() => "Pas de résultat"}
      />
    </Form.Group>
  );
}
export default RessourcesSelecteur;
