// src/app.js
import React from "react";
import Accueil from "./pages/Accueil.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Fiche from "./pages/Fiche.js";
import Annuaire from "./pages/Annuaire.js";
import NouvelleFiliere from "./pages/NouvelleFiliere.js";
import ModifierFiliere from "./pages/ModifierFiliere.js";

function App() {
  return (
    <Router>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">
          <div className="mx-3">Retrace</div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/annuaire">
              Annuaire
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/ajouterFiliere" element={<NouvelleFiliere />} />
        <Route path="/annuaire" element={<Annuaire />} />
        <Route path="/modifierFiliere" element={<ModifierFiliere />} />
        <Route path="/fiche/:idFiche" element={<Fiche />} />
      </Routes>
    </Router>
  );
}

export default App;
