//components/ServicesSelecteur.js

import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { fetchServices } from "../services/api.js";

function ServicesSelecteur({ valueSelected, allowCreate, selectedValues }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (selectedValues) {
      const formattedSelectedValues = selectedValues.map((value) => ({
        value: value,
        label: value,
      }));
      setSelectedOption(formattedSelectedValues);
    }
  }, [selectedValues]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    valueSelected(selectedOption.map((item) => item.value));
  };

  const fetchOptions = async () => {
    try {
      const response = await fetchServices();
      const data = await response;

      const formattedOptions = data.map((item) => ({
        value: item,
        label: item,
      }));

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const SelectComponent = allowCreate ? Creatable : Select;

  return (
    <Form.Group>
      <Form.Label>Services</Form.Label>
      <SelectComponent
        isMulti
        value={selectedOption}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleChange}
        placeholder={"Selectionner un ou plusieurs..."}
        formatCreateLabel={(inputValue) => `Ajouter : "${inputValue}"`}
        noOptionsMessage={() => "Pas de résultat"}
      />
    </Form.Group>
  );
}
export default ServicesSelecteur;
