import React, { useState, useEffect } from "react";
import { Form, Stack, Button, Row, Alert, Col, Container, Modal } from "react-bootstrap";
import TypeSelecteur from "../components/TypeSelecteur.js";
import DepartementSelecteur from "../components/DepartementSelecteur.js";
import RessourcesSelecteur from "../components/RessourcesSelecteur.js";
import OperationsSelecteur from "../components/OperationsSelecteur.js";
import CreatableSelect from "react-select/creatable";
import ServicesSelecteur from "../components/ServicesSelecteur.js";
import CapaciteSelecteur from "../components/CapaciteSelecteur.js";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import isEmail from "validator/es/lib/isEmail.js";
import isURL from "validator/es/lib/isURL.js";
import { fetchId, updateFiliere } from "../services/api.js";
import ModalContact from "../components/ModalContact.js";

const formDataObject = {
  nom: "",
  fournisseur_reemploi: false,
  repreneur_reemploi: false,
  fournisseur_recyclage: false,
  repreneur_recyclage: false,
  type: [],
  insertion: false,
  ressources_entrantes: [],
  operations: [],
  services: [],
  adresse: "",
  departement: [],
  site_web: "",
  capacite: "",
  interlocuteur: "",
  mail: [],
  contact: [],
  telephone: [],
  commentaires: "",
  statut: "Actif",
  update: "Mis à jour",
  ressources_sortantes: [],
};

const ModifierFiliere = ({ id, onClose }) => {
  const [formData, setFormData] = useState(formDataObject);
  const [isValid, setIsValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setEditingIndex(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchId(id);
        setFormData(data);
        console.log("data", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    console.log("Updated formData", formData);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRessourcesChange = (selectedRessources) => {
    setFormData({ ...formData, ressources_entrantes: selectedRessources });
  };

  const handleDepartementChange = (selectedRessources) => {
    setFormData({ ...formData, departement: selectedRessources });
  };

  const handleServicesChange = (selectedRessources) => {
    setFormData({ ...formData, services: selectedRessources });
  };

  const handleTypeChange = (selectedRessources) => {
    setFormData({ ...formData, type: selectedRessources });
  };

  const handleOperationChange = (selectedRessources) => {
    setFormData({ ...formData, operations: selectedRessources });
  };

  const handleCapaciteChange = (valueSelected) => {
    setFormData({ ...formData, ["capacite"]: valueSelected.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await updateFiliere(id, formData);
      console.log(response);
      onClose();
    } catch (error) {
      console.error("Error updating form:", error);
    }
  };

  const handleChangeUrl = (event) => {
    const url = event.target.value;
    const { name } = event.target;
    setIsValid(isURL(url));
    setFormData({ ...formData, [name]: url });
  };

  const handleDeleteContact = (index) => {
    const updatedContacts = formData.contact.filter((_, idx) => idx !== index);
    setFormData({ ...formData, contact: updatedContacts });
  };

  const handleEditContact = (index) => {
    setEditingIndex(index);
    setShowModal(true);
  };

  const addContact = (contact) => {
    if (editingIndex !== null) {
      const updatedContacts = [...formData.contact];
      updatedContacts[editingIndex] = contact;
      setFormData({ ...formData, contact: updatedContacts });
    } else {
      setFormData({
        ...formData,
        contact: [...formData.contact, contact],
      });
    }
    handleClose();
  };

  return (
    <Container className="d-flex justify-content-center p-3">
      <Col lg={10} sm={12} md={8}>
        <Form onSubmit={handleSubmit}>
          <Form.Group id="nom" className="my-2">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" name="nom" value={formData.nom} onChange={handleChange} />
          </Form.Group>
          <Form.Group id="type" className="my-2">
            <TypeSelecteur valueSelected={handleTypeChange} allowCreate={true} selectedValues={formData.type} />
          </Form.Group>
          <Form.Group id="reemploi" className="my-2">
            <Form.Check
              inline
              type="checkbox"
              name="fournisseur_reemploi"
              label="Fournisseur de matériaux de réemploi"
              checked={formData.fournisseur_reemploi}
              onChange={handleChange}
            />
            <Form.Check
              id="repreneur-reemploi"
              inline
              type="checkbox"
              name="repreneur_reemploi"
              label="Repreneur de matériaux de réemploi"
              checked={formData.repreneur_reemploi}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group id="recyclage" className="my-2">
            <Form.Check
              id="fournisseur-recyclage"
              inline
              type="checkbox"
              name="fournisseur_recyclage"
              label="Fournisseur de matériaux recyclés"
              checked={formData.fournisseur_recyclage}
              onChange={handleChange}
            />
            <Form.Check
              id="repreneur-recyclage"
              inline
              type="checkbox"
              name="repreneur_recyclage"
              label="Repreneur de matériaux pour recyclage"
              checked={formData.repreneur_recyclage}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group id="insertion" className="my-4">
            <Form.Check
              type="checkbox"
              name="insertion"
              label="Emploi de travailleurs en insertion"
              checked={formData.insertion}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group id="ressources" className="my-2">
            <RessourcesSelecteur
              valueSelected={handleRessourcesChange}
              allowCreate={true}
              selectedValues={formData.ressources_entrantes}
            />
          </Form.Group>
          <Form.Group id="operations" className="my-2">
            <OperationsSelecteur valueSelected={handleOperationChange} selectedValues={formData.operations} />
          </Form.Group>
          <Form.Group id="services" className="my-2">
            <ServicesSelecteur
              valueSelected={handleServicesChange}
              allowCreate={true}
              selectedValues={formData.services}
            />
          </Form.Group>
          <Form.Group id="adresse-depart" className="my-2">
            <Row>
              <Col lg={7} sm={12}>
                <Form.Label>Adresse principale</Form.Label>
                <Form.Control type="text" name="adresse" value={formData.adresse} onChange={handleChange} />
              </Col>
              <Col lg={5} sm={12}>
                <DepartementSelecteur
                  valueSelected={handleDepartementChange}
                  allowCreate={true}
                  selectedValues={formData.departement}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group id="site-web" className="my-2">
            <Form.Label>Site Web</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!isValid}
              name="site_web"
              value={formData.site_web}
              onChange={handleChangeUrl}
            />
          </Form.Group>
          <Form.Group id="capacite" className="my-2">
            <CapaciteSelecteur valueSelected={handleCapaciteChange} selectedValues={formData.capacite} />
          </Form.Group>
          <Form.Group id="contact" className="my-2">
            <Form.Label className="me-2">Contact</Form.Label>
            <Button variant="outline-secondary" onClick={() => setShowModal(true)}>
              Ajouter un contact
            </Button>
            {formData.contact && formData.contact.length > 0 ? (
              <Stack className="my-2" direction="horizontal" gap={2}>
                {formData.contact.map((con, index) => (
                  <div key={index} className="d-flex align-items-center">
                    <span>
                      <Button onClick={() => handleEditContact(index)}>
                        {con.prenom} {con.nom}
                      </Button>
                      <Button
                        className="mx-1"
                        variant="danger"
                        onClick={() => handleDeleteContact(index)}
                        title="Supprimer le contact"
                      >
                        ×
                      </Button>
                    </span>
                  </div>
                ))}
              </Stack>
            ) : null}
            <ModalContact
              show={showModal}
              handleClose={() => setShowModal(false)}
              addContact={addContact}
              editingContact={editingIndex !== null ? formData.contact[editingIndex] : null}
            />
          </Form.Group>
          <Form.Group id="commentaires" className="my-2">
            <Form.Label>Commentaires</Form.Label>
            <Form.Control as="textarea" name="commentaires" value={formData.commentaires} onChange={handleChange} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Modifier
          </Button>
        </Form>
      </Col>
    </Container>
  );
};

export default ModifierFiliere;
