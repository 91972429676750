// src/services/api.js

import axios from "axios";

// const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = "https://app.retrace.fr/api";
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "x-api-key": `${process.env.REACT_APP_API_KEY}`,
    "Content-Type": "application/json",
  },
});

export const fetchItems = async (searchQuery) => {
  console.log("search query before axios", searchQuery);
  try {
    const response = await axiosInstance.get("/filieres", {
      params: {
        nom: searchQuery.nom,
        ressources_entrantes: searchQuery.ressources_entrantes,
        type: searchQuery.type,
        departement: searchQuery.departement,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const fetchActivites = async () => {
  try {
    const response = await axiosInstance.get("/activite");
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const fetchTypes = async () => {
  try {
    const response = await axiosInstance.get("/type");
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const fetchDepartement = async () => {
  try {
    const response = await axiosInstance.get("/departement");
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const fetchRessourcesEnt = async () => {
  try {
    const response = await axiosInstance.get("/ressourcesent");
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const fetchId = async (searchQuery) => {
  try {
    const response = await axiosInstance.get(`/id/${searchQuery}`, {});
    return response.data;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};

export const fetchOperations = async () => {
  try {
    const response = await axiosInstance.get("/operations");
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const fetchServices = async () => {
  try {
    const response = await axiosInstance.get("/services");
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const fetchCapacite = async () => {
  try {
    const response = await axiosInstance.get("/capacite");
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const postNewFiliere = async (filiereData) => {
  try {
    const response = await axiosInstance.post("/nouvelleFiliere", filiereData);
    return response.data;
  } catch (error) {
    console.error("Error fetching distinct values:", error);
    throw error;
  }
};

export const updateFiliere = async (id, filiereData) => {
  try {
    const response = await axiosInstance.post("/modifierFiliere", { id, ...filiereData });
    return response.data;
  } catch (error) {
    console.error("Error updating filiere:", error);
    throw error;
  }
};
