// src/Fiche.js
import React, { useState, useEffect } from "react";
import { InputGroup, Form, Button, Col, Row, ListGroup, Container, Badge, Stack, Card, Modal } from "react-bootstrap";
import { fetchId } from "../services/api.js";
import { useParams, Link } from "react-router-dom";
import { Phone, Copy, EnvelopeAt, CheckLg, CheckAll } from "react-bootstrap-icons";
import ModifierFiliere from "./ModifierFiliere.js";

function Fiche() {
  const [response, setResponse] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [ressourcesEnt, setRessourcesEnt] = useState([]);
  const [type, setType] = useState([]);
  const [departement, setDepartement] = useState([]);
  const [commentaires, setCommentaires] = useState("");
  const [siteWeb, setSiteWeb] = useState("");
  const [mail, setMail] = useState([]);
  const [tel, setTel] = useState([]);
  const [services, setServices] = useState([]);
  const [contact, setContact] = useState({});
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);
  let { idFiche } = useParams();
  const [copiedPhone, setCopiedPhone] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchId(idFiche);
        setResponse(res);
        setTel(res.telephone);
        setRessourcesEnt(res.ressources_entrantes);
        setType(res.type);
        setDepartement(res.departement);
        setSiteWeb(res.site_web);
        setMail(res.mail);
        setServices(res.services);
        setContact(res.contact);
        setCommentaires(res.commentaires);
        console.log(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [idFiche]);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedPhone(text);
        setTimeout(() => setCopiedPhone(null), 2000);
      })
      .catch((error) => {
        console.error("Erreur de copie", error);
      });
  };

  return (
    <Container>
      <ModalModifier id={idFiche} show={showModal} handleCloseModal={handleClose} />
      <Row className="my-3">
        <Col lg={9} md={11} sm={11}>
          {response && <h1>{response.nom}</h1>}
        </Col>
        <Col lg={3} md={1} sm={1}>
          <Button variant="outline-secondary" onClick={handleShow}>
            Modifier
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Stack direction="horizontal" className="flex-wrap" gap={1}>
          {type.length === 1 ? (
            <Button size="sm" variant="outline-success" disabled key={0}>
              {type[0]}
            </Button>
          ) : (
            type.map((type, index) => (
              <Button size="sm" variant="outline-success" disabled key={index}>
                {type}
              </Button>
            ))
          )}
        </Stack>
      </Row>
      <Row>
        <Col sm={12} lg={6} className="mb-3">
          <h4>Adresse</h4>
          {response.adresse}
        </Col>
        <Col sm={12} lg={6}>
          <h4>Département</h4>
          <Stack direction="horizontal" className="flex-wrap" gap={1}>
            {departement.length === 1 ? (
              <Button size="sm" variant="outline-success" disabled key={0}>
                {departement[0]}
              </Button>
            ) : (
              departement.map((departements, index) => (
                <Button size="sm" variant="outline-success" disabled key={index}>
                  {departements}
                </Button>
              ))
            )}
          </Stack>
        </Col>
      </Row>
      <Row className="my-3">
        <Col sm={12} lg={6} className="mb-3">
          <h4>Ressources entrantes</h4>
          <Stack direction="horizontal" className="flex-wrap" gap={1}>
            {ressourcesEnt.length === 1 ? (
              <Button size="sm" variant="outline-success" disabled key={0}>
                {ressourcesEnt[0]}
              </Button>
            ) : (
              ressourcesEnt.map((ressources, index) => (
                <Button size="sm" variant="outline-success" disabled key={index}>
                  {ressources}
                </Button>
              ))
            )}
          </Stack>
        </Col>
        <Col sm={12} lg={6}>
          <h4>Services</h4>
          {services && services.length > 0 ? (
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {services.map((serv, index) => (
                <li key={index}>{serv}</li>
              ))}
            </ul>
          ) : services && services.length === 1 ? (
            <p>{services[0]}</p>
          ) : (
            <p></p>
          )}
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <h4>Site web</h4>
          <a href={siteWeb} target="_blank">
            {siteWeb}
          </a>
        </Col>
      </Row>
      <Row className="my-3">
        <Col lg={12} sm={12} md={12}>
          <h4>Contact</h4>
          <Stack direction="horizontal" className="flex-wrap" gap={3}>
            {Array.isArray(contact) && contact.length > 0 ? (
              contact.map((cont, index) => (
                <Card key={index} className="p-3 card-width">
                  <Card.Title>
                    {cont.prenom} {cont.nom}
                  </Card.Title>
                  <Card.Subtitle>
                    <div>{cont.fonction}</div>
                  </Card.Subtitle>
                  <Card.Body>
                    {cont.email && (
                      <div>
                        <EnvelopeAt /> {cont.email}
                        <span className="mx-2" onClick={() => handleCopyToClipboard(cont.email)}>
                          {copiedPhone === cont.email ? <CheckAll /> : <Copy />}
                        </span>
                      </div>
                    )}
                    {cont.telephone && (
                      <div>
                        <Phone /> {cont.telephone}
                        <span className="mx-2" onClick={() => handleCopyToClipboard(cont.telephone)}>
                          {copiedPhone === cont.telephone ? <CheckAll /> : <Copy />}
                        </span>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              ))
            ) : (
              <p>Aucun contact</p>
            )}
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Commentaires</h4>
          {commentaires}
        </Col>
      </Row>
    </Container>
  );
}

export default Fiche;

const ModalModifier = ({ id, show, handleCloseModal }) => {
  return (
    <Modal size="xl" show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton={true}>
        <h1>Modifier</h1>
      </Modal.Header>
      <ModifierFiliere onClose={handleCloseModal} id={id} />
    </Modal>
  );
};

export { ModalModifier };
