// src/Annuaire.js
import React, { useState, useEffect } from "react";
import {
  InputGroup,
  Card,
  CardBody,
  CardTitle,
  Form,
  Button,
  Col,
  Row,
  ListGroup,
  Container,
  Stack,
  Badge,
} from "react-bootstrap";
import { fetchItems } from "../services/api.js";
import { Typeahead, AsyncTypeahead } from "react-bootstrap-typeahead";
import TypeSelecteur from "../components/TypeSelecteur.js";
import { useParams, Link } from "react-router-dom";
import RessourcesSelecteur from "../components/RessourcesSelecteur.js";
import DepartementSelecteur from "../components/DepartementSelecteur.js";

function Annuaire() {
  const [response, setResponse] = useState([]);
  const [searchItem, setSearchItem] = useState();
  const [ressources, setRessources] = useState();
  const [typesFilieres, setTypesFilieres] = useState();
  const [departFilieres, setDepartFilieres] = useState();

  const query = {
    nom: searchItem,
    ressources_entrantes: ressources,
    type: typesFilieres,
    departement: departFilieres,
  };

  const getItems = async () => {
    console.log("query", query);
    try {
      const fetchResponse = await fetchItems(query);
      setResponse(fetchResponse);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  function setSearch(inputText) {
    setSearchItem(inputText);
  }

  function showResults() {
    getItems();
  }

  const handleRessourceChange = (valueSelected) => {
    setRessources(valueSelected);
    console.log("ressources", valueSelected);
  };

  const handleTypeChange = (valueSelected) => {
    setTypesFilieres(valueSelected);
    console.log("type", valueSelected);
  };

  const handleDepartChange = (valueSelected) => {
    setDepartFilieres(valueSelected);
    console.log("type", valueSelected);
  };

  return (
    <Container className="my-3">
      <Row>
        <h1>Annuaire des acteurs du réemploi</h1>
      </Row>
      <Row>
        <Col sm={3}>
          <Form>
            <Form.Group>
              <Form.Label>Nom</Form.Label>
              <Form.Control type="text" placeholder="Nom de la filière" onChange={(e) => setSearch(e.target.value)} />
            </Form.Group>
            <Form.Group className="my-2">
              <RessourcesSelecteur allowCreate={false} valueSelected={handleRessourceChange} />
            </Form.Group>
            <Form.Group className="my-2">
              <TypeSelecteur valueSelected={handleTypeChange} />
            </Form.Group>
            <Form.Group className="my-2">
              <DepartementSelecteur valueSelected={handleDepartChange} />
            </Form.Group>
            <Form.Group className="my-2">
              <Button onClick={showResults}>Chercher</Button>
            </Form.Group>
          </Form>
        </Col>
        <Col sm={9}>
          <Row>
            <Col>
              <b>{response.length} Résultats</b>
            </Col>
          </Row>
          <Row /* className="justify-content-center" */>
            <div className="row row-cols-md-4">
              {response.map((item, index) => (
                <Card style={{ padding: ".5rem", margin: ".5rem" }} key={index}>
                  <a
                    href={`/fiche/${item._id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Card.Title>{item.nom}</Card.Title>
                    <Card.Body style={{ padding: "0px" }}>
                      <Stack direction="horizontal" className="flex-wrap" gap={1}>
                        {item.type.map((ty, indexty) => (
                          <Badge key={indexty}>{ty}</Badge>
                        ))}
                      </Stack>
                    </Card.Body>
                  </a>
                </Card>
              ))}
            </div>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Annuaire;
